import React from 'react';
import translations from './languages'; // Import the translations

const Footer = ({ toggleTradingView, toggleWalletModal, toggleEventModal, openNotificationModal,  language }) => {

  const t = translations[language];

  return (
    <footer className="footer text-white p-2 flex justify-between fixed inset-x-4 bottom-4 z-50 rounded-lg shadow-neon-top">
      <button onClick={toggleTradingView} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-chart-line text-lg"></i>
        <span className="mt-1 text-sm">{t.trading}</span>
      </button>
      
      {/* Botón de Events, llamará a la función openEventModal */}
      <button onClick={toggleEventModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-clock text-lg"></i>
        <span className="mt-1 text-sm">{t.events}</span>
      </button>
      
      <button onClick={toggleWalletModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-wallet text-lg"></i>
        <span className="mt-1 text-sm">{t.wallet}</span>
      </button>
      
      <button className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-dollar-sign text-lg"></i>
        <span className="mt-1 text-sm">{t.earn}</span>
      </button>
      <button className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
      <i className="fas fa-store text-lg"></i>
      <span className="mt-1 text-sm">{t.store}</span>
      </button>
      
      {/*<button onClick={openNotificationModal} className="flex-1 mx-1 bg-orange-500 text-white py-2 px-2 rounded-lg flex flex-col items-center">
        <i className="fas fa-bell text-lg"></i>
        <span className="mt-1 text-sm">{t.notify}</span>
      </button>*/}
    </footer>
  );
};

export default Footer;
