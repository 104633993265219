import React, { useState, useEffect } from 'react';
import translations from './languages'; // Import the translations
// Función de EventModal para mostrar los últimos 100 eventos
const EventModal = ({ isOpen, closeModal, language }) => {
  const [events, setEvents] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const t = translations[language];
  
  useEffect(() => {
    const controller = new AbortController();
  
    if (isOpen) {
      fetch('/api/bot-events-db', { signal: controller.signal })
        .then((response) => response.json())
        .then((data) => {
          setEvents(data.slice(0, 100)); // Limita a los últimos 100 eventos
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error('Error al obtener los eventos del bot:', error);
          }
        });
    }
  
    return () => {
      controller.abort();  // Aborta el fetch si el modal se cierra o el componente se desmonta
    };
  }, [isOpen]);
  

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setEvents([]); // Restablece los eventos
      closeModal();
      setIsClosing(false);
    }, 300);
  };
  

  if (!isOpen && !isClosing) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
      <div
        className={`modal-content bg-black p-6 shadow-lg shadow-neon-top rounded-lg ${
          isClosing ? 'animate-slideDown' : 'animate-slideUp'
        }`}
      >
        <div  className="modal-header">
         {/* Botón de cerrar en la esquina superior derecha */}
         <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <i className="fas fa-times text-xl"></i>
        </button>

        {/* Título centrado */}
        <h2 className="text-xl text-orange-500 mb-4 text-center">{t.last100events}</h2>
        </div>
        {/* Sección de eventos: últimos 100 eventos usando ul y li */}
        <div className="mb-4">
          <ul className="overflow-y-auto h-48 bg-gray-900 p-4 rounded-lg">
            {events.length > 0 ? (
              events.map((event, index) => {
                const profitPercentage = parseFloat(event.profitPercentage);

                // Determinar el ícono y color según el porcentaje de ganancia/pérdida
                let icon, profitColor;

                if (profitPercentage > 0.01) {
                  icon = <i className="fas fa-arrow-up"></i>; // Flecha arriba para ganancias
                  profitColor = 'text-green-500 shadow-neon-top-green'; // Verde para ganancias
                } else if (profitPercentage < -0.01) {
                  icon = <i className="fas fa-arrow-down"></i>; // Flecha abajo para pérdidas
                  profitColor = 'text-red-500 shadow-neon-bottom-red'; // Rojo para pérdidas
                } else {
                  icon = <i className="fas fa-minus"></i>; // Guión para valores cercanos a 0
                  profitColor = 'text-gray-500'; // Gris para neutro
                }

                return (
                  <li key={index} className="content-event-modal mb-2 p-2 rounded bg-gray-800">
                    {/* Icono de Fecha */}
                    <div className="flex items-center text-sm text-gray-400">
                      <i className="fas fa-calendar-alt mr-1"></i>
                      <p>{new Date(event.date).toLocaleString()}</p>
                    </div>

                    {/* Icono de Precio */}
                    <div className="flex items-center text-sm text-gray-400">
                      <i className="fas fa-dollar-sign mr-1"></i>
                      <p>${Intl.NumberFormat('en', { style: 'decimal' }).format(event.price.toFixed(2))}</p>
                    </div>

                    {/* Ganancia/Pérdida */}
                    <div className={`profit flex items-center ${profitColor}`}>
                      {icon}
                      <p className="ml-1">{profitPercentage.toFixed(2)}%</p>
                    </div>
                  </li>
                );
              })
            ) : (
              <li className="text-gray-400 text-center">Loading events...</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EventModal;
