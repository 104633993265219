import React, { useState, useEffect, useContext } from 'react';
import BotStatus from './BotStatus';
import Events from './Events';
import translations from './languages'; // Importar las traducciones
import Tooltip from './MessageHeader'; // Asegúrate de importar el componente MessageHeader
import { useTooltip  } from './TooltipContext'; // Contexto para el sistema de alerta

const MainContent = ({ language, isModalOpen  }) => {
  const [botStatus, setBotStatus] = useState('HOLD');
  const [btcPrice, setBtcPrice] = useState('-');
  const [lastOperationTime, setOperationTime] = useState();
  const [isOperating, setIsOperating] = useState(false); // Si el bot está operando o no
  const [events, setEvents] = useState([]); // Almacenar los eventos
  const [selectedDays, setSelectedDays] = useState(1); // Estado para seleccionar días
  const [profitEffect, setProfitEffect] = useState(null);
  const [lastProcessedEventId, setLastProcessedEventId] = useState(null); // Nuevo estado para el último evento procesado
  const { tooltipMessages } = useTooltip(); // Obtener el mensaje de alerta desde el contexto

  const [profitSums, setProfitSums] = useState({
    sumLast1Day: {},
    sumLast3Days: {},
    sumLast7Days: {},
    sumLast10Days: {},
    sumLast15Days: {},
    sumLast30Days: {},
  });

  const t = translations[language];
   
  const triggerProfitEffect = (isProfitPositive, profitPercentage) => {
    // Crear el mensaje con el valor de profitPercentage
    const message = `${isProfitPositive ? '+' : ''}${profitPercentage.toFixed(2)}%`;
  
    // Establecer el efecto en el estado
    setProfitEffect(message);
  
    // Remover el efecto después de 2 segundos
    setTimeout(() => {
      setProfitEffect(null);
    }, 2000);
  };

  useEffect(() => {
   if (isModalOpen) {
      return; // Pausar las peticiones fetch si un modal está abierto
    }
 
    const controller = new AbortController();
    const signal = controller.signal;
  
    fetchBotDataAndEvents(signal); // Fetch inicial
  
    const interval = setInterval(() => {
      fetchBotDataAndEvents(signal); // Refrescar cada 10 segundos
    }, 10000);
  
    return () => {
      clearInterval(interval);
      controller.abort(); // Limpiar al desmontar o cuando cambia el estado de los modales
    };
  }, [isModalOpen]); // Dependencia de isModalOpen

  // Función para obtener el estado del bot y eventos desde el backend
  const fetchBotDataAndEvents = async (signal) => {
    try {
      const [botStatusResponse, botEventsResponse] = await Promise.all([
        fetch('/api/bot-status', { signal }),
        fetch('/api/bot-events-db', { signal })
      ]);

      const botStatusData = await botStatusResponse.json();
      const botEventsData = await botEventsResponse.json();

      setBotStatus(botStatusData.signal);
      setBtcPrice(botStatusData.currentPrice);
      setOperationTime(botStatusData.lastOperationTime);
      setIsOperating(botStatusData.isOperating); // Actualiza si el bot está operando o no
      setEvents(botEventsData); // Actualiza los eventos del bot
      
      // Obtener el último evento
      const latestEvent = botEventsData[0]; // Asumiendo que el primer evento es el último

      if (latestEvent && latestEvent.profitPercentage !== undefined) {
        // Comprobar si ya procesamos este evento
        if (lastProcessedEventId !== latestEvent.id) {
          const isProfitPositive = latestEvent.profitPercentage > 0;
          triggerProfitEffect(isProfitPositive, latestEvent.profitPercentage);
  
          // Actualizar el último evento procesado para evitar duplicación
          setLastProcessedEventId(latestEvent.id);
        }
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Solicitud abortada');
      } else {
        console.error('Error al obtener los datos del bot y eventos:', error);
      }
    }
  };

  // useEffect para recalcular las sumas de eventos cuando estos cambien
  useEffect(() => {
    setProfitSums({
      sumLast1Day: calculateProfitSum(events, 1),
      sumLast3Days: calculateProfitSum(events, 3),
      sumLast7Days: calculateProfitSum(events, 7),
      sumLast10Days: calculateProfitSum(events, 10),
      sumLast15Days: calculateProfitSum(events, 15),
      sumLast30Days: calculateProfitSum(events, 30),
    });
  }, [events]);

  // Función que separa y suma los valores positivos y negativos dentro de los últimos X días
  const calculateProfitSum = (events, days) => {
    const currentDate = new Date().getTime(); // Usar milisegundos para mayor precisión
    const millisecondsInADay = 1000 * 3600 * 24;
    
    let positiveSum = 0;
    let negativeSum = 0;

    events.forEach(event => {
      const eventDate = new Date(event.date).getTime();
      const differenceInDays = (currentDate - eventDate) / millisecondsInADay;

      if (differenceInDays <= days) {
        if (event.profitPercentage > 0) {
          positiveSum += event.profitPercentage;
        } else if (event.profitPercentage < 0) {
          negativeSum += event.profitPercentage;
        }
      }
    });

    return {
      positiveSum: positiveSum.toFixed(2),
      negativeSum: negativeSum.toFixed(2),
      totalSum: (positiveSum + negativeSum).toFixed(2),
    };
  };

  // Función para determinar la suma de ganancias/pérdidas según los días seleccionados
  const getSelectedSum = () => {
    switch (selectedDays) {
      case 1:
        return profitSums.sumLast1Day;
      case 3:
        return profitSums.sumLast3Days;
      case 7:
        return profitSums.sumLast7Days;
      case 10:
        return profitSums.sumLast10Days;
      case 15:
        return profitSums.sumLast15Days;
      case 30:
        return profitSums.sumLast30Days;
      default:
        return profitSums.sumLast1Day;
    }
  };

  // Función para obtener el ícono y el color según el porcentaje de ganancia/pérdida
  const getProfitIconAndColor = (percentage) => {
    const iconUp = <i className="fas fa-arrow-up"></i>;
    const iconDown = <i className="fas fa-arrow-down"></i>;
    const iconNeutral = <i className="fas fa-minus"></i>;

    if (percentage > 0.01) {
      return { icon: iconUp, color: 'text-green-500 shadow-neon-top-green' }; // Ganancia
    } else if (percentage < -0.01) {
      return { icon: iconDown, color: 'text-red-500 shadow-neon-bottom-red' }; // Pérdida
    } else {
      return { icon: iconNeutral, color: 'text-gray-500' }; // Neutro
    }
  };

  // Determinar el ícono según el porcentaje de ganancia/pérdida
  const totalPercentageProfit = parseFloat(getSelectedSum().totalSum);
  const { icon, color: profitColor } = getProfitIconAndColor(totalPercentageProfit);

  const dayOptions = [
    { value: 1, label: `1 ${t.day}` },
    { value: 3, label: `3 ${t.day}` },
    { value: 7, label: `7 ${t.day}` },
    { value: 10, label: `10 ${t.day}` },
    { value: 15, label: `15 ${t.day}` },
    { value: 30, label: `30 ${t.day}` },
  ];

  return (

    <div className="main-content relative">
      <div className="main-content-inner">
        <main className="flex-1 mt-[80px] mb-[80px] p-6">
         {/* Mostrar todas las alertas */}
      <div>
        {tooltipMessages.map((msg, index) => (
          <div key={index} className="tooltip-alert">{msg}</div>
        ))}
      </div>
            {/* Mostrar la ganancia/pérdida total */}
            <div className="content-total-profit profit mt-2 text-l text-center">
            {t.currenttotal}{' '}
            <div className="flex items-center justify-between py-3">
              <select
                className="select-days bg-gray-200 px-6 py-2 rounded-lg"
                value={selectedDays}
                onChange={(e) => setSelectedDays(parseInt(e.target.value))}
              >
                {dayOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            {' '} {t.gainloss}{' '}
            <div className={`profit flex items-center ${profitColor}`}>
              {icon}
              <span className="ml-1">
                <p>{totalPercentageProfit.toFixed(2)}%</p>
              </span>
            </div>
          </div>
           {/* Mostrar el precio actual de BTCUSDT */}
           <div className="price mt-6 text-2xs text-center">
            BTC/USDT: <span className="text-orange-500">${new Intl.NumberFormat('en', { currency: 'USD' }).format(btcPrice)}</span>
          </div>
          {/* Mostrar el estado del bot */}
          <BotStatus status={botStatus} isOperating={isOperating} language={language} lastOperationTime={lastOperationTime} />
 {/* Mostrar el efecto si profitEffect tiene algún valor */}
 {profitEffect && (
      <div className="profit-effect-wrapper">
        <div className={`profit-effect left ${profitEffect.startsWith('+') ? 'positive' : 'negative'}`}>
          <span className="profit-icon">
            {profitEffect.startsWith('+') ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down"></i>}
          </span>
          <span className="profit-item">{profitEffect}</span>
        </div>

        <div className={`profit-effect center ${profitEffect.startsWith('+') ? 'positive' : 'negative'}`}>
          <span className="profit-icon">
            {profitEffect.startsWith('+') ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down"></i>}
          </span>
          <span className="profit-item">{profitEffect}</span>
        </div>

        <div className={`profit-effect right ${profitEffect.startsWith('+') ? 'positive' : 'negative'}`}>
          <span className="profit-icon">
            {profitEffect.startsWith('+') ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-down"></i>}
          </span>
          <span className="profit-item">{profitEffect}</span>
        </div>
      </div>
    )}
            {/* Mostrar los eventos del bot */}
          <Events eventList={events} language={language} />
 <Tooltip />
        </main>
      </div>
    </div>
  );
};

export default MainContent;
