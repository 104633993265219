import React, { useEffect, useState } from 'react';

const UserModal = ({ isOpen, closeModal, telegramUserId, language }) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300); // Duración de la animación
  };

  if (!isOpen && !isClosing) return null;

   return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
    <div
      className={`modal-content  bg-white p-6 shadow-lg shadow-neon-top rounded relative ${
        isClosing ? 'animate-slideDown' : 'animate-slideUp'
      }`}
    >
         {/* Botón de cerrar en la esquina superior derecha */}
         <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-black"
        >
          <i className="fas fa-times text-xl"></i>
        </button>

       {/* Título centrado */}
       <h2 className="text-xl text-orange-500 mb-6 text-center">User Panel</h2>
      </div>
    </div>
  );
};

export default UserModal;
