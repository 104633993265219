import React from 'react';

const NetworkSelector = React.memo(({ selectedNetwork, handleNetworkChange, saveNetworkPreference }) => {
  return (

    <div className="p-6 bg-gray-800 text-white rounded-lg shadow-lg text-center">
      <h2 className="text-xl font-bold mb-4">Seleccionar Red</h2>
      {/* Selector de red */}
      <select
        className="w-full p-2 bg-gray-700 text-white rounded-lg mb-4"
        value={selectedNetwork}
        onChange={(e) => {
          const network = e.target.value;
          handleNetworkChange(network); // Actualizar red seleccionada
          saveNetworkPreference(network); // Guardar preferencia de red
        }}
      >
        <option value="">Selecciona tu red</option>
        <option value="BSC">Binance Chain (BEP2)</option>
        <option value="ERC20">Ethereum (ERC20)</option>
        <option value="TRC20">Tron (TRC20)</option>
        <option value="Polygon">Polygon (MATIC)</option>
        <option value="TON">TON</option>      
      </select>

      {/* Mostrar advertencia si no hay red seleccionada */}
      {!selectedNetwork && (
        <p className="text-yellow-400 mt-4">Por favor selecciona una red para continuar.</p>
      )}

    </div>      

  );
});

export default NetworkSelector;
