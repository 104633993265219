import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Asegúrate de tener esta biblioteca instalada
import translations from './languages'; // Importar las traducciones
import { useTooltip } from './TooltipContext';

const Header = React.memo(({ 
  toggleConfigPanel, 
  firstName, 
  lastName, 
  telegramUserId, 
  language, 
  toggleConnectWalletModal, 
  user_Network, 
  balance, 
  gain_loss,
  isBotOperating 
}) => {
  const [selectedNetwork, setSelectedNetwork] = useState(user_Network);
  const [currentBalance, setCurrentBalance] = useState(balance);
  const [gainLoss, setGainLoss] = useState(gain_loss);
  const [isMarket, setIsMarket] = useState(true); // Estado para el switch Market/No Market
  const [showName, setShowName] = useState(true); // Estado para mostrar/ocultar nombre
  const [showBalance, setShowBalance] = useState(true); // Estado para mostrar/ocultar saldo
  const { setTooltipAlert } = useTooltip();

  const t = translations[language];

    const handleSwitchChange = () => {
      if (isBotOperating) {
        const message = isMarket
          ? "No puedes salir del mercado: Bot operando"
          : "No puedes entrar al mercado: Bot operando";
          setTooltipAlert(message); // Muestra el mensaje en el tooltip
        return; // No permite cambiar el estado si el bot está operando
      }
  
      // Si el bot no está operando, permite el cambio de isMarket
      setIsMarket(!isMarket);
    };

  useEffect(() => {
    setSelectedNetwork(user_Network);
    setCurrentBalance(balance);
    setGainLoss(gain_loss);
  }, [user_Network, balance, gain_loss]);

  const getNetworkAvatar = (network) => {
    switch (network) {
      case 'BNB':
        return 'assets/bnb-avatar.webp';
      case 'ETH':
        return 'assets/eth-avatar.webp';
      case 'MATIC':
        return 'assets/matic-avatar.webp';
      case 'TRX':
        return 'assets/trx-avatar.webp';
      case 'BSC':
        return 'assets/bnb-avatar.webp';
      case 'TON':
        return 'assets/ton-avatar.webp';
      default:
        return 'assets/default-avatar.png';
    }
  };

  const formatCurrency = (amount) => new Intl.NumberFormat('en').format(amount);

  return (
<header className="header-content bg-gray-900 text-white p-4 flex items-center justify-between shadow-lg shadow-neon-bottom border-b-2 border-orange-500 fixed top-0 inset-x-0 z-50">
<div className="flex justify-between items-center w-full ">
  {/* Avatar de red */}
  <div className="flex-shrink-0">
    <img
      src={getNetworkAvatar(selectedNetwork)}
      alt="Icono de Red"
      className="w-12 h-12 rounded-full border-2 border-orange-500 cursor-pointer hover:opacity-90 transition-opacity duration-300"
      onClick={toggleConnectWalletModal}
    />
  </div>
 {/* Nombre y saldo */}
 <div className="flex flex-col items-end space-y-2 ml-4">
        <p className="text-base font-semibold text-gray-100">
          <i className="fas fa-user text-orange-500 mr-2"></i>
          {showName ? `${firstName} ${lastName}` : '********'} {/* Oculta el nombre */}
        </p>
        <p className="flex items-center text-sm text-gray-400">
          <i className="fas fa-wallet text-orange-500 mr-2"></i>
          {showBalance ? `$${formatCurrency(currentBalance)} USDT` : '***********'} {/* Oculta el saldo */}
        </p>
      </div>

      {/* Botones para ocultar/mostrar nombre y saldo */}
      <div className="flex flex-col items-end space-y-2  ml-4">
        {/* Botón para ocultar/mostrar nombre */}
        <button
          className="text-gray-400 hover:text-gray-200 focus:outline-none"
          onClick={() => setShowName(!showName)} // Alterna el estado de showName
        >
          {showName ? (
            <i className="fas fa-eye"></i> // Ojo cerrado (ocultar)
          ) : (
            <i className="fas fa-eye-slash"></i> // Ojo abierto (mostrar)
          )}
        </button>

        {/* Botón para ocultar/mostrar saldo */}
        <button
          className="text-gray-400 hover:text-gray-200 focus:outline-none"
          onClick={() => setShowBalance(!showBalance)} // Alterna el estado de showBalance
        >
          {showBalance ? (
            <i className="fas fa-eye"></i> // Ojo cerrado (ocultar)
          ) : (
            <i className="fas fa-eye-slash"></i> // Ojo abierto (mostrar)
          )}
        </button>
      </div>
</div>
{/* Switch y Ganancias/Pérdidas */}
<div className="flex flex-col items-end space-y-2">
  {/* Interruptor de Market/No Market */}
  <label className="flex items-center">
  <span className="mr-2 text-sm">{isMarket ? "Market" : "No Market"}</span>
  <label className="switch">
        <input
          type="checkbox"
          checked={isMarket}
          onChange={handleSwitchChange} // Llama a la función personalizada
          
        />
        <span className="slider round"></span>
  </label>
  </label>

  {/* Ganancias/Pérdidas */}
  {gainLoss !== 0 && showBalance && (
    <span className="flex items-center">
      {gainLoss > 0 ? (
        <div className="profit flex items-center text-green-500 shadow-neon-top-green text-sm">
          <i className="fas fa-arrow-up text-sm mr-1"></i>
          <p className="text-sm">{gainLoss}%</p>
        </div>
      ) : (
        <div className="profit flex items-center text-red-500 shadow-neon-bottom-red text-sm">
          <i className="fas fa-arrow-down text-sm mr-1"></i>
          <p className="text-sm">{gainLoss}%</p>
        </div>
      )}
    </span>
  )}
</div>


  {/* Botón de configuración */}
  <div>
    <button
      onClick={toggleConfigPanel}
      className="bg-orange-500 hover:bg-orange-600 text-white p-2 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-300 transition-all transform hover:scale-110"
      aria-label="Configuración"
    >
      <i className="fas fa-cog text-lg"></i>
    </button>
  </div>
 
</header>


  );
});

// Define PropTypes
Header.propTypes = {
  toggleConfigPanel: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  telegramUserId: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  toggleConnectWalletModal: PropTypes.func.isRequired,
  user_Network: PropTypes.string.isRequired,
  balance: PropTypes.number.isRequired,
  gain_loss: PropTypes.number.isRequired,
};

export default Header;
