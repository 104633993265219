import React, { useState, useEffect, useMemo } from 'react';
import NetworkSelector from './NetworkSelector';
import WalletModal from './WalletModal';
//TonConnect
import { TonConnectButton, useTonAddress , useIsConnectionRestored } from '@tonconnect/ui-react';
import { RainbowKitProvider, lightTheme, darkTheme, ConnectButton, WalletButton } from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi';
const WalletConnectModal = ({ isOpen, closeModal, selectedNetwork, handleNetworkChange, saveNetworkPreference, currentbalance, CommissionRate, isBotOperating, telegramUserId, language }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isWallet, setIsWallet] = useState(false); // Booleano para saber si está conectado
  const tonAddress = useTonAddress(); 
  const connectionRestored = useIsConnectionRestored();
  const [walletModalOpen, setWalletModalOpen] = useState(false); // Estado para controlar el modal de conexión de wallet
  const { address, isConnected } = useAccount();
  
  useEffect(() => {
    if (isOpen) {

      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);
  
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300); // Duración de la animación
  };


// Este effect se ejecutará para todas las redes excepto TON
useEffect(() => {
  if (selectedNetwork === 'TON') return; // Si la red es TON, no ejecutar este effect
  //if (isWallet) return;

  if (isConnected) {
    setWalletAddress(address); // Almacena la dirección de la billetera cuando está conectado
    setIsWallet(true); // Indica que la billetera está conectada
    saveWalletToDB(telegramUserId, selectedNetwork, address);
  } else {
    setWalletAddress(null); // Resetea la dirección si no está conectado
    setIsWallet(false); // Indica que la billetera no está conectada
    disconnectWallet();
  }
}, [isConnected, address, selectedNetwork, telegramUserId]);

// Manejo de la conexión restaurada para TON
useEffect(() => {
  if (selectedNetwork !== 'TON') return; // Este effect solo se ejecutará si la red es TON
  if (isWallet) return;
  // Manejo de conexión en la red TON
  if (connectionRestored && tonAddress) {
    setWalletAddress(tonAddress);
    setIsWallet(true);
    saveWalletToDB(telegramUserId, 'TON', tonAddress); // Guardar en la base de datos
  } else {
    setWalletAddress(null); // Resetea la dirección si no está conectado
    setIsWallet(false); // Indica que la billetera no está conectada
    disconnectWallet();
  }
}, [connectionRestored, tonAddress, telegramUserId, selectedNetwork]);

  // Guardar la dirección de la billetera en la base de datos
  const saveWalletToDB = (telegramId, network, address) => {
    fetch('/api/connectWallet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telegramId, network, address }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Billetera conectada:', data);
      })
      .catch((error) => {
        console.error('Error al guardar la billetera en la base de datos:', error);
      });
  };

  // Desconectar la billetera
  const disconnectWallet = () => {
    fetch('/api/disconnectWallet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ telegramId: telegramUserId, network: selectedNetwork }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('Billetera desconectada:', data);
        setWalletAddress(null); // Limpiar la dirección de la billetera
      })
      .catch((error) => {
        console.error('Error al desconectar la billetera:', error);
      });
  };

  const handleDepositUSDT = () => {
    handleClose();
  };

  const showWalletConnectButton = useMemo(() => {
    return (
      (selectedNetwork === 'ERC20' ||
        selectedNetwork === 'Polygon' ||
        selectedNetwork === 'BSC') &&
      !walletAddress
    );
  }, [selectedNetwork, walletAddress]);

  if (!isOpen && !isClosing) return null;

  return (
    <RainbowKitProvider
    coolMode
      theme={{
        lightMode: lightTheme(),
        darkMode: darkTheme(),
        overlayBlur: 'small',
      }}
      modalSize="compact"
    >
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
        <div
          className={`modal-content bg-white p-6 shadow-lg shadow-neon-top rounded relative ${
            isClosing ? 'animate-slideDown' : 'animate-slideUp'
          }`}
        >
          <button
            onClick={handleClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-black"
          >
            <i className="fas fa-times text-xl"></i>
          </button>

          {/* Mostrar la dirección de la billetera conectada */}
          {isWallet ? (
            <div className="mt-4">
              <h2 className="text-xl text-orange-500 mb-6 text-center">Billetera Conectada</h2>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {selectedNetwork === 'TON' && <TonConnectButton />}
                {showWalletConnectButton && (              
                  <ConnectButton />
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className="mt-6 bg-orange-500 hover:bg-orange-600 px-4 py-2 rounded-lg w-full text-white text-lg"
                  onClick={() => setWalletModalOpen(true)}
                >
                  Depositar USDT ({selectedNetwork})
                </button>
              </div>
              {/* Modal para conectar la wallet */}
      {walletModalOpen && (
        <WalletModal
        isOpen={true}
        closeModal={closeModal}
        usdtAddress={walletAddress}
        currentbalance={currentbalance}
        CommissionRate={CommissionRate}
        selectedNetwork={selectedNetwork}
        isWallet={isWallet}
        isBotOperating={isBotOperating}
        telegramUserId={telegramUserId}
        language={language}
        />
      )}
            </div>
            
          ) : (
            <div className="mt-4">
              <h2 className="text-xl text-orange-500 mb-6 text-center">Connect Your Wallet</h2>

              {/* Siempre mostrar el NetworkSelector si no hay una red seleccionada */}
             
                <NetworkSelector
                  selectedNetwork={selectedNetwork}
                  handleNetworkChange={handleNetworkChange}
                  saveNetworkPreference={saveNetworkPreference}
                />
              

              {/* Mostrar botones de conexión si hay una red seleccionada */}
              {selectedNetwork && (
                <div className="text-center mt-4">
                  <p className="text-black">Selecciona tu billetera en {selectedNetwork}</p>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {selectedNetwork === 'TON' && !walletAddress && <TonConnectButton />}
                    {showWalletConnectButton && 
                    <ConnectButton />}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </RainbowKitProvider>
  );
};
export default WalletConnectModal;
