// TooltipContext.js
import React, { createContext, useState, useContext } from 'react';

const TooltipContext = createContext();

export const TooltipProvider = ({ children }) => {
  const [tooltipMessages, setTooltipMessages] = useState([]); // Inicializa como un arreglo vacío

  const setTooltipAlert = (message) => {
    console.log('Tooltip message:', message); // Verifica si la función está siendo llamada
    setTooltipMessages(prevMessages => [...prevMessages, message]);

    setTimeout(() => {
      setTooltipMessages(prevMessages => prevMessages.filter(msg => msg !== message));
    }, 3000); // Duración del tooltip visible
  };

  return (
    <TooltipContext.Provider value={{ setTooltipAlert, tooltipMessages }}> {/* Asegúrate de exportar tooltipMessages */}
      {children}
    </TooltipContext.Provider>
  );
};

export const useTooltip = () => useContext(TooltipContext);
