import React, { useState, useEffect } from 'react';

import { getDefaultConfig  } from '@rainbow-me/rainbowkit';
import { rainbowWallet, walletConnectWallet, binanceWallet, bybitWallet, trustWallet, braveWallet } from '@rainbow-me/rainbowkit/wallets';
import { WagmiProvider } from 'wagmi';
import { mainnet, polygon, bsc , tron} from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export function WagmiModalProvider({ children, selected_Network }) {

  // Función para actualizar la configuración según la red seleccionada
    let chains;

    // Switch para definir las redes compatibles según selectedNetwork
    switch (selected_Network) {
      case 'ETH':
        chains = [mainnet];
        break;
      case 'BSC':
        chains = [bsc];
        break;
      case 'MATIC':
        chains = [polygon];
        break;
      case 'TRX':
        chains = [tron];
        break;
      default:
        chains = [mainnet, bsc, polygon, tron];
    }

    
  const config = getDefaultConfig({
    appName: 'Opti Gain',
    projectId: 'c47d64e61a16712fa776c251da3d6232',
    chains,
    ssr: true, // If your dApp uses server side rendering (SSR)
  });
  
  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
      </WagmiProvider> 
  );
}
