import React, { useEffect, useState, useRef } from 'react';

const TradingViewModal = ({ isOpen, closeModal }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado para el loader
  const chartContainerRef = useRef(null); // Referencia al contenedor del gráfico

  useEffect(() => {
    if (isOpen && window.TradingView) {
      // Verificar que el contenedor existe antes de crear el widget
      if (chartContainerRef.current) {
        new window.TradingView.widget({
          container_id: chartContainerRef.current.id,
          width: '100%',
          height: 400,
          symbol: 'BINANCE:BTCUSDT',
          interval: '1',
          timezone: 'Etc/UTC',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          withdateranges: false,
          hide_side_toolbar: true,
          allow_symbol_change: false,
          save_image: false,
          studies: ['MACD@tv-basicstudies'],
          onready: () => {
            setIsLoading(false); // El gráfico ha terminado de cargar
          }
        });
      }
    } else if (!window.TradingView) {
      console.error("TradingView script not loaded.");
    }

    return () => {
      if (!isOpen && chartContainerRef.current) {
        // Limpiar el gráfico cuando el modal se cierre
        chartContainerRef.current.innerHTML = '';
      }
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300);
  };

  if (!isOpen && !isClosing) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-end justify-center z-50">
      <div
        className={`modal-content bg-white p-6 shadow-lg shadow-neon-top rounded ${
          isClosing ? 'animate-slideDown' : 'animate-slideUp'
        }`}
      >
        {/* Botón de cerrar */}
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-400 hover:text-white"
        >
          <i className="fas fa-times text-xl"></i>
        </button>

        {/* Título centrado */}
        <h2 className="text-xl text-orange-500 mb-4 text-center">Trading Chart</h2>

        {/* Loader mientras se carga el gráfico */}
        {isLoading && (
          <div className="loader flex justify-center items-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {/* Contenedor del gráfico TradingView */}
        <div
          id="tradingview_chart"
          ref={chartContainerRef}
          style={{ width: '100%', height: '400px' }}
        ></div>
      </div>
    </div>
  );
};

export default TradingViewModal;
