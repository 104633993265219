
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CouponModal from './CouponModal'; // Importar el modal de cupones
import { BOT_ADDRESS_USDT_TON, BOT_ADDRESS_USDT_ERC20, BOT_ADDRESS_USDT_BSC, BOT_ADDRESS_USDT_TRC20, BOT_ADDRESS_USDT_POLYGON, TON_MEMO } from '../wallets/config';
import WalletConnectModal from './WalletConnectModal.js'; // Importar el modal de conexión de wallet
import { handleWithdrawalWithCommission } from '../wallets/withdraw';
import { Address, beginCell, toNano } from 'ton-core';

import { useTonAddress, useTonConnectUI  } from '@tonconnect/ui-react';
import { useConnect } from 'wagmi';
import { ethers, getBigInt , parseUnits  } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';

//import TronWeb from 'tronweb';

/*const tronWeb = new TronWeb({
  fullHost: 'https://api.trongrid.io', // TRON mainnet
  privateKey: 'YOUR_PRIVATE_KEY', // O puedes usar el proveedor de la billetera conectada
});*/

const WalletModal = ({
  isOpen,
  closeModal,
  currentbalance,
  CommissionRate,
  selectedNetwork,
  handleNetworkChange,
  saveNetworkPreference,
  isBotOperating,
  telegramUserId,
  language
}) => {
  const [depositAmount, setDepositAmount] = useState(''); // Estado para la cantidad a depositar
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [couponModalOpen, setCouponModalOpen] = useState(false); // Estado para controlar el modal de cupones
  const [walletConnectModalOpen, setWalletConnectModalOpen] = useState(false); // Estado para controlar el modal de conexión de wallet
  const [selectedCoupon, setSelectedCoupon] = useState(null); // Cupón seleccionado
  const [currentCommissionRate, setCurrentCommissionRate] = useState(parseFloat(CommissionRate?.toFixed(2) || 10.0));
  const [gasFee, setGasFee] = useState(0); // Estado para el gas fee
  const [wallets, setWallets] = useState([]); // Estado para todas las direcciones de depósito
  const [is_BotOperating, setisBotOperating] = useState(isBotOperating); // Estado para todas las direcciones de depósito
  const [depositAddress, setDepositAddress] = useState(''); // Dirección de depósito de la red seleccionada
  const [memoTag, setMemoTag] = useState(''); // memoTag único
  const [tonAddress] = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const [walletAddress, setWalletAddress] = useState(null);
  const [isWallet, setIsWallet] = useState(false); // Booleano para saber si está conectado
  const [selected_Network, setNetwork] = useState(selectedNetwork);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar el spinner
  const { connector } = useConnect(); // Obtener el conector de la billetera

  const usdtAbi = [
                 'function transfer(address to, uint256 amount) public returns (bool)',
                   ];           
  // Validar y manejar el retiro
  const handleWithdraw = () => {
    if (withdrawAmount <= 0 || withdrawAmount > currentbalance) {
      alert('La cantidad a retirar no es válida o excede el saldo disponible.');
      return;
    }
    else if (!isWallet)
    {
      alert('No tienes una Billetera conectada');
      return;
    }

    const netAmount = calculateFinalAmount(withdrawAmount); // Calcular el monto neto
    console.log(`Monto neto a recibir: ${netAmount} USDT`);

    handleWithdrawalWithCommission(withdrawAmount, walletAddress, selected_Network, currentCommissionRate, is_BotOperating);
  };

  // Aplicar el cupón seleccionado
  const applyCoupon = (coupon) => {
    const newCommissionRate = (currentCommissionRate - coupon.discount).toFixed(2); // Aplicar el descuento
    setCurrentCommissionRate(parseFloat(newCommissionRate));
    setSelectedCoupon(coupon); // Guardar cupón seleccionado
    setCouponModalOpen(false); // Cerrar el modal después de seleccionar el cupón
  };
  
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true); // Iniciar la carga cuando se abre el modal

      // Hacer una petición al backend para verificar el estado de la billetera
      fetch(`/api/user/verify?telegram_id=${telegramUserId}`)
        .then((res) => res.json())
        .then((data) => {
          // Convertir isWallet de 1/0 a true/false en el frontend
          const isWallet = data.isWallet === 1 ? true : false;
          
          setWalletAddress(data.wallet_Address); // Establecer la dirección de la billetera
          setIsWallet(isWallet); // Actualizar el estado de isWallet
          setNetwork(data.user_network);
        })
        .catch((error) => {
          console.error('Error al verificar el estado de la billetera:', error);
        })
        .finally(() => {
          setIsLoading(false); // Finalizar la carga al completar la petición
        });
 
    }
  }, [isOpen, telegramUserId]);

// Manejar el depósito
const handleDeposit = async () => {
  if (!depositAmount || depositAmount < 10) {
    alert('Por favor, ingresa una cantidad válida. Mínimo 10 USDT');
    return;
  }

  try {
    if (selected_Network === 'TON') {
      await handleTonDeposit(); // Lógica separada para TON
    } else if (selected_Network === 'ERC20') {
      await handleEthDeposit(); // Lógica para ETH
    } else if (selected_Network === 'BSC') {
      await handleBscDeposit(); // Lógica para BSC
    } /*else if (selected_Network === 'TRC20') {
      await handleTrc20Deposit(); // Lógica para TRC20
    } */else if (selected_Network === 'Polygon') {
      await handlePolygonDeposit(); // Lógica para Polygon
    } 
  } catch (error) {
    console.error('Error al procesar el depósito:', error);
    alert('Error durante el proceso de depósito. Inténtalo nuevamente.');
  }
};

// Lógica para el depósito en TON
async function handleTonDeposit() {
  const JettonWalletContractAddress = Address.parse('EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'); // Dirección del contrato Jetton
  const userJettonWalletAddress  = Address.parse(walletAddress); // Dirección del Usuario
  const destinationAddress = Address.parse(BOT_ADDRESS_USDT_TON); // Dirección de depósito de Bybit en TON
  const memoTag = TON_MEMO; // Tag o memo personalizado para la transacción
  const depositAmountWithDecimals = depositAmount * 1_000_000; // Ajuste para decimales

  if (walletAddress) {
    try {
      // Crear el payload para la transacción
      const forwardPayload = beginCell()
        .storeUint(0, 32)
        .storeStringTail(memoTag)
        .endCell();

      // Crear el cuerpo de la transacción
      const body = beginCell()
        .storeUint(0xf8a7ea5, 32) // Código de operación para transferir Jettons
        .storeUint(0, 64) // ID de la consulta (puede ser 0)
        .storeCoins(toNano(depositAmountWithDecimals)) // Monto de USDT en TON
        .storeAddress(destinationAddress) // Dirección de destino (Bybit)
        .storeAddress(userJettonWalletAddress) // Dirección del monedero del usuario
        .storeBit(0) // No rebotar si la transferencia falla
        .storeCoins(toNano("0.02")) // Comisiones de gas en TON
        .storeBit(1) // Indica que hay un payload adicional
        .storeRef(forwardPayload) // Adjuntar el payload
        .endCell();

      // Crear la solicitud de transacción
      const transactionRequest = {
        validUntil: Math.floor(Date.now() / 1000) + 60, // Validez de 60 segundos
        messages: [
          {
            address: JettonWalletContractAddress.toString(),
            amount: (0.02 * Math.pow(10, 9)).toString(), // Monto en TON para cubrir comisiones
            payload: body.toBoc().toString("base64"), // Cuerpo codificado en base64
          },
        ],
      };

      // Enviar la transacción con TonConnect
      const result = await tonConnectUI.sendTransaction(transactionRequest);
      alert('Solicitud de pago enviada a tu billetera TON.');

      if (result.boc) {
        const txID = result.transactionId;
        const blockHash = result.blockHash;

        // Enviar los datos del depósito al backend
        await fetch('/api/deposit_usdt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
          txID: txID,
          amount: depositAmountWithDecimals,
          toAddress: destinationAddress.toString(),
          chain: 'TON',
          blockHash: blockHash,
          telegram_id: telegramUserId
          })
        });

        alert('Depósito registrado con éxito.');
      } else {
        console.error('No se encontró BOC en el resultado de la transacción');
      }

    } catch (error) {
      console.error('Error al enviar la solicitud de pago con TonConnect:', error);
      alert('Error al enviar la solicitud de pago.');
    }
  } else {
    alert('Conecta tu billetera TON antes de realizar un depósito.');
  }
}

async function handleEthDeposit() {
  try {

    if (!connector  || !walletAddress) {
      alert('Conecta tu billetera antes de realizar el depósito.');
      return;
    }

    // Dirección del contrato de USDT (ERC20) en la red Ethereum
    const usdtContract = new ethers.Contract('0xdAC17F958D2ee523a2206206994597C13D831ec7', usdtAbi, connector );

    // Convertir el monto de USDT a base 6 decimales (USDT tiene 6 decimales)
    const amount = parseUnits(depositAmount.toString(), 6);

    // Verificar si hay suficiente ETH para cubrir el gas
    const balance = await connector.getBalance();
    const gasPrice = await connector.provider.getGasPrice();
    const estimatedGas = await usdtContract.estimateGas.transfer(BOT_ADDRESS_USDT_ERC20, amount);

    const gasCost = estimatedGas.mul(gasPrice);

    // Comprobar si el balance de ETH es suficiente para cubrir el costo del gas
    if (balance.lt(gasCost)) {
      throw new Error('Fondos insuficientes para pagar las comisiones de gas');
    }
    const gasLimitBuffer = getBigInt(10000); // Create a BigInt
    // Realizar la transacción para transferir USDT
    const tx = await usdtContract.transfer(BOT_ADDRESS_USDT_ERC20, amount, {
      gasLimit: estimatedGas + gasLimitBuffer
    });
    console.log('Transacción enviada:', tx.hash);

    // Esperar confirmación de la transacción
    await tx.wait();
    console.log('Transacción confirmada:', tx.hash);
    alert('Transacción completada con éxito');

    // Puedes enviar los detalles de la transacción al backend
    await fetch('/api/deposit_usdt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({   
        txID: tx.hash,
        amount: depositAmount,
        toAddress: BOT_ADDRESS_USDT_ERC20,
        chain: 'ERC20',
        blockHash: '',
        telegram_id: telegramUserId
      })
    });

  } catch (error) {
    console.error('Error al enviar USDT:', error);
    alert(`Error al enviar USDT: ${error.message}`);
  }
}

async function handleBscDeposit() {
  try {

    if (!connector  || !walletAddress) {
      alert('Conecta tu billetera antes de realizar el depósito.');
      return;
    }

    // Dirección del contrato USDT en BSC
    const usdtContract = new ethers.Contract('0x55d398326f99059fF775485246999027B3197955', usdtAbi, connector );

    // Convertir el monto de USDT a base 6 decimales (USDT tiene 6 decimales)
    const amount = parseUnits(depositAmount.toString(), 6);

    // Verificar si hay suficiente BNB para cubrir el gas (gas en BSC se paga con BNB)
    const balance = await connector.getBalance();
    const gasPrice = await connector.provider.getGasPrice();
    const estimatedGas = await usdtContract.estimateGas.transfer(BOT_ADDRESS_USDT_BSC, amount);

    const gasCost = estimatedGas.mul(gasPrice);

    // Comprobar si el balance de BNB es suficiente para cubrir el costo del gas
    if (balance.lt(gasCost)) {
      throw new Error('Fondos insuficientes para pagar las comisiones de gas en BNB');
    }
    const gasLimitBuffer = getBigInt(10000); // Create a BigInt
    // Realizar la transacción para transferir USDT
    const tx = await usdtContract.transfer(BOT_ADDRESS_USDT_BSC, amount, {
      gasLimit: estimatedGas + gasLimitBuffer
    });
    console.log('Transacción enviada:', tx.hash);

    // Esperar confirmación de la transacción
    await tx.wait();
    console.log('Transacción confirmada:', tx.hash);
    alert('Transacción completada con éxito');

    // Enviar los detalles de la transacción al backend
    await fetch('/api/deposit_usdt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        txID: tx.hash,
        amount: depositAmount,
        toAddress: BOT_ADDRESS_USDT_BSC,
        chain: 'BSC',
        blockHash: '',
        telegram_id: telegramUserId
      })
    });

  } catch (error) {
    console.error('Error al enviar USDT en BSC:', error);
    alert(`Error al enviar USDT en BSC: ${error.message}`);
  }
}

async function handlePolygonDeposit() {
  try {
    

    if (!connector  || !walletAddress) {
      alert('Conecta tu billetera antes de realizar el depósito.');
      return;
    }

    // Dirección del contrato de USDT (ERC20) en la red Polygon
    const usdtContract = new ethers.Contract('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', usdtAbi, connector );

    // Convertir el monto de USDT a base 6 decimales (USDT tiene 6 decimales)
    const amount = parseUnits(depositAmount.toString(), 6);

    // Verificar si hay suficiente MATIC para cubrir el gas (Polygon usa MATIC para el gas)
    const balance = await connector.getBalance();
    const gasPrice = await connector.provider.getGasPrice();
    const estimatedGas = await usdtContract.estimateGas.transfer(BOT_ADDRESS_USDT_POLYGON, amount);

    const gasCost = estimatedGas.mul(gasPrice);

    // Comprobar si el balance de MATIC es suficiente para cubrir el costo del gas
    if (balance.lt(gasCost)) {
      throw new Error('Fondos insuficientes para pagar las comisiones de gas en MATIC');
    }
    const gasLimitBuffer = getBigInt(10000); // Create a BigInt
    // Realizar la transacción para transferir USDT en Polygon
    const tx = await usdtContract.transfer(BOT_ADDRESS_USDT_POLYGON, amount, {
      gasLimit: estimatedGas + gasLimitBuffer
    });
    console.log('Transacción enviada:', tx.hash);

    // Esperar confirmación de la transacción
    await tx.wait();
    console.log('Transacción confirmada:', tx.hash);
    alert('Transacción completada con éxito');

    // Enviar los detalles de la transacción al backend
    await fetch('/api/deposit_usdt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        txID: tx.hash,
        amount: depositAmount,
        toAddress: BOT_ADDRESS_USDT_TRC20,
        chain: 'Polygon',
        blockHash: '',
        telegram_id: telegramUserId
      })
    });

  } catch (error) {
    console.error('Error al enviar USDT en Polygon:', error);
    alert(`Error al enviar USDT en Polygon: ${error.message}`);
  }
}
/*
async function handleTrc20Deposit() {
  try {
    // Dirección del contrato USDT en TRC20
    const usdtContractAddress = 'TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9'; // USDT en TRC20

    // Convertir el monto de USDT a base 6 decimales (USDT tiene 6 decimales)
    const depositAmountInTRC20 = tronWeb.toSun(depositAmount); // Convierte la cantidad a SUN (unidad mínima en TRON)

    // Obtener la cuenta conectada (en caso de usar TronLink)
    const userAddress = tronWeb.defaultAddress.base58;

    // Verificar si hay suficiente TRX para pagar el gas
    const trxBalance = await tronWeb.trx.getBalance(userAddress);
    const gasPrice = await tronWeb.trx.getBandwidthPrice(); // En TRON, el gas se paga con ancho de banda

    if (trxBalance < gasPrice) {
      throw new Error('Fondos insuficientes para pagar las comisiones de gas en TRX');
    }

    // Instanciar el contrato USDT en TRC20
    const usdtContract = await tronWeb.contract().at(usdtContractAddress);

    // Realizar la transacción para transferir USDT
    const tx = await usdtContract.transfer(BOT_ADDRESS_USDT_TRC20, depositAmountInTRC20).send({
      feeLimit: 1000000, // Límite de comisiones en TRX (1 TRX)
      callValue: 0, // No enviar TRX, solo tokens
    });

    console.log('Transacción enviada:', tx);

    // Esperar la confirmación de la transacción
    const receipt = await tronWeb.trx.getTransactionInfo(tx);
    if (receipt.result) {
      alert('Transacción completada con éxito');

      // Enviar los detalles de la transacción al backend
      await fetch('/api/deposit_usdt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          txID: tx.hash,
          amount: depositAmount,
          toAddress: BOT_ADDRESS_USDT_TRC20,
          chain: 'TRC20',
          blockHash: '',
          telegram_id: telegramUserId
        })
      });
    } else {
      console.error('Error en la confirmación de la transacción');
    }

  } catch (error) {
    console.error('Error al enviar USDT en TRC20:', error);
    alert(`Error al enviar USDT en TRC20: ${error.message}`);
  }
}*/



  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeModal();
      setIsClosing(false);
    }, 300); // Duración de la animación de cierre
  };

  const handleConnectWallet = () => {
    setWalletConnectModalOpen(true);
  };

  // Calcular monto final para el retiro
  const calculateFinalAmount = (withdrawAmount) => {
    const commissionAmount = (withdrawAmount * currentCommissionRate) / 100;
    const finalAmount = withdrawAmount - commissionAmount - gasFee;
    return finalAmount > 0 ? finalAmount.toFixed(2) : 0; // Asegurarse de que el monto no sea negativo
  };
  
  return (
    <>
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
        <div
          className={`modal-content relative bg-black p-8 shadow-lg shadow-neon-top rounded-lg w-full max-w-lg mx-auto ${
            isClosing ? 'animate-slideDown' : 'animate-slideUp'
          }`}
        >
          <div className="modal-header">
            {/* Botón de cerrar */}
            <button
              onClick={handleClose}
              className="absolute top-4 left-4 text-gray-400 hover:text-white focus:outline-none"
            >
              <i className="fas fa-times text-2xl"></i>
            </button>
            {/* Título */}
            <h2 className="text-2xl text-orange-500 mb-6 text-center">Gestión de USDT</h2>
          </div>
  
          {isLoading ? (
            <div className="spinner">
              <p>Cargando...</p>
              {/* Aquí puedes agregar un spinner visual */}
            </div>
          ) : (
            <>
              {/* Mostrar contenido diferente según si el usuario tiene una wallet conectada */}
              {isWallet ? (
                <>
                  {/* Mostrar saldos */}
                  <div className="mb-6">
                    <div className="flex justify-between space-x-6">
                      {/* Saldo actual */}
                      <div className="flex-1">
                        <label className="block mb-2 text-white text-xl">Saldo actual:</label>
                        <div className="bg-gray-900 p-3 rounded-lg text-white text-sm">
                          {new Intl.NumberFormat('en', { currency: 'USD' }).format(currentbalance)} USDT
                        </div>
                      </div>
                    </div>
                  </div>
  
                  {/* Sección de depósito */}
                  <label className="block mb-2 text-white text-l">Depositar USDT (Red: {selected_Network})</label>
                  <div className="mb-6">
                    <input
                      type="number"
                      className="bg-gray-700 p-2 rounded-lg w-full text-white"
                      placeholder="0.00"
                      value={depositAmount}
                      onChange={(e) => setDepositAmount(e.target.value)}
                    />
                    <button
                      onClick={handleDeposit}
                      className="mt-6 bg-orange-500 hover:bg-orange-600 px-4 rounded-lg w-full text-white text-lg"
                    >
                      Depositar
                    </button>
                  </div>
  
                  {/* Sección de retiro */}
                  <label className="block mb-2 text-white text-l">Cantidad a retirar</label>
                  <div className="mb-6">
                    <input
                      type="number"
                      className="bg-gray-700 p-2 rounded-lg w-full text-white"
                      placeholder="0.00"
                      value={withdrawAmount}
                      onChange={(e) => setWithdrawAmount(e.target.value)}
                    />
                    <button
                      onClick={() => handleWithdraw()}
                      className="mt-6 bg-orange-500 hover:bg-orange-600 px-4 rounded-lg w-full text-white text-lg"
                    >
                      Retirar
                    </button>
  
                    {/* Mostrar detalles de comisión, gas y monto final */}
                    <div className="text-white text-sm mt-4">
                      {withdrawAmount && (
                        <>
                          <div className="flex items-center justify-between">
                            <p className="text-sm">
                              Comisión OptiGain:
                              <span className="text-orange-500">{' ' + currentCommissionRate} %</span>
                            </p>
                            <button
                              onClick={() => setCouponModalOpen(true)}
                              className="mt-4 bg-orange-500 hover:bg-orange-600 px-4 py-3 rounded-lg w-full text-white text-sm"
                            >
                              Usar Cupón
                            </button>
                          </div>
  
                          <div className="mt-2 flex items-center justify-between">
                            <p className="text-sm">
                              GasFee Red {selected_Network}:
                              <span className="text-orange-500">{' ' + new Intl.NumberFormat('en').format(gasFee)} USDT</span>
                            </p>
                          </div>
  
                          <p className="mt-4 text-xl">
                            Monto final a recibir:
                            <span className="text-green-500">{' ' + new Intl.NumberFormat('en').format(calculateFinalAmount(withdrawAmount))} USDT</span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Mostrar solo saldos y mensaje de conectar wallet */}
                  <div className="mb-6">
                    <div className="flex justify-between space-x-6">
                      <div className="flex-1">
                        <label className="block mb-2 text-white text-xl">Saldo actual:</label>
                        <div className="bg-gray-900 p-3 rounded-lg text-white text-sm">
                          {new Intl.NumberFormat('en', { currency: 'USD' }).format(currentbalance)} USDT
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <p className="text-red-500 text-center mt-4">Debes conectar tu wallet para depositar USDT.</p>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      onClick={handleConnectWallet}
                      className="mt-6 bg-orange-500 hover:bg-orange-600 px-4 py-2 rounded-lg w-full text-white text-lg"
                    >
                      Conectar Wallet
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
  
      {/* Modal para conectar la wallet */}
      {walletConnectModalOpen && (
        <WalletConnectModal
          isOpen={true}
          closeModal={closeModal}
          selectedNetwork={selected_Network}
          handleNetworkChange={handleNetworkChange}
          saveNetworkPreference={saveNetworkPreference}
          currentbalance={currentbalance}
          CommissionRate={CommissionRate}
          isBotOperating={isBotOperating}
          telegramUserId={telegramUserId}
          language={language}
        />
      )}
  
      {/* Modal de cupones */}
      {couponModalOpen && (
        <CouponModal applyCoupon={applyCoupon} closeModal={() => setCouponModalOpen(false)} />
      )}
    </>
  );
  
};

export default WalletModal;
